import moment from "moment";
moment.updateLocale("ja", {
  months: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月",],
  weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
});

import { originKbnList } from '@/store/mainpage/mainPageCommon';

//ヘッダー列
export const headerColKeys = [
  'no',
  'centerCD',
  'centerNM',
  'group1NM',
  'makerCD',
  'makerNM',
  'itemNM',
  'janCD',
  'itemCD',
  'categoryCD',
  'categoryNM',
  'teika',
  'blIrisu',
  'csIrisu',
  'shubaiDate',
  'teiban',
  'torihikisakiCD',
  'tokuisakiCD',
  'tokuisakiNM',
  'orderType',
  'orderNo',
  'localSlipNo',
  'slipNo',
  'detailNo',
  'syukkaDate',
  'nouhinDate',
  'keppinKingaku',
  'jutyuPS',
  'nouhinPS',
  'keppinPS',
  'jutyuBL',
  'nouhinBL',
  'keppinBL',
  'tanaban',
  'origin',
  'keppin',
  'biko',
  'responsibility',
  'reason',
  'updateUser',
  'updateDate',
  'yoteiDte',
  'yoteiBL',
];

export const headersCol = [
  {
    no: '　',
    centerCD: '倉庫',
    centerNM: '倉庫',
    itemCD: '商品属性',
    itemNM: '商品属性',
    janCD: "商品属性",
    makerCD: "商品属性",
    makerNM: "商品属性",
    categoryCD: "商品属性",
    categoryNM: "商品属性",
    blIrisu: "商品属性",
    csIrisu: "商品属性",
    teika: "商品属性",
    shubaiDate: '商品属性',
    teiban: '商品属性',
    group1NM: '',
    tokuisakiCD: '得意先',
    tokuisakiNM: '得意先',
    torihikisakiCD: '得意先',
    slipNo: '伝票',
    detailNo: '伝票',
    syukkaDate: '伝票',
    nouhinDate: '伝票',
    jutyuPS: '伝票',
    jutyuBL: '伝票',
    nouhinPS: '伝票',
    nouhinBL: '伝票',
    keppinPS: '伝票',
    keppinBL: '伝票',
    keppinKingaku: '伝票',
    localSlipNo: '伝票',
    orderNo: '伝票',
    orderType: '伝票',
    tanaban: '',
    origin: '欠品理由',
    keppin: '欠品理由',
    biko: '欠品理由',
    responsibility: '欠品理由',
    reason: '欠品理由',
    updateUser: '登録状況',
    updateDate: '登録状況',
    yoteiDte: '次回入庫予定(一般)',
    yoteiBL: '次回入庫予定(一般)',
  },
  {
    no: 'No.',
    centerCD: 'CD',
    centerNM: '名称',
    itemCD: '商品CD',
    itemNM: '商品名',
    janCD: "JAN",
    makerCD: "ﾒｰｶｰCD",
    makerNM: "ﾒｰｶｰ名",
    categoryCD: "ｶﾃｺﾞﾘCD",
    categoryNM: "ｶﾃｺﾞﾘ名",
    blIrisu: "BL入数",
    csIrisu: "CS入数",
    teika: "定価",
    shubaiDate: '終売日',
    teiban: '定番フラグ',
    group1NM: '企業グループ1',
    tokuisakiCD: 'CD',
    tokuisakiNM: '名称',
    torihikisakiCD: '取引先CD',
    slipNo: '得意先伝票No.',
    detailNo: '明細No.',
    syukkaDate: '出荷日',
    nouhinDate: '納品日',
    jutyuPS: '受注バラ',
    jutyuBL: '受注BL',
    nouhinPS: '納品バラ',
    nouhinBL: '納品BL',
    keppinPS: '欠品バラ',
    keppinBL: '欠品BL',
    keppinKingaku: '欠品金額',
    localSlipNo: '社内伝票No.',
    orderNo: '受注No.',
    orderType: '受注種別',
    tanaban: '棚番',
    origin: '発生個所',
    keppin: '欠品理由',
    biko: '備考',
    responsibility: '責任所在',
    reason: 'ADMS設定欠品理由',
    updateUser: '更新者',
    updateDate: '更新日時',
    yoteiDte: '入庫予定日',
    yoteiBL: '入庫予定BL',
    },
];


export const selectableCol =
{
  no: false,
  centerCD: true,
  centerNM: true,
  itemCD: true,
  itemNM: true,
  janCD: true,
  makerCD: true,
  makerNM: true,
  categoryCD: true,
  categoryNM: true,
  blIrisu: true,
  csIrisu: true,
  teika: true,
  shubaiDate: true,
  teiban: true,
  tokuisakiCD: true,
  tokuisakiNM: true,
  torihikisakiCD: true,
  slipNo: true,
  detailNo: true,
  syukkaDate: true,
  nouhinDate: true,
  jutyuPS: true,
  jutyuBL: true,
  nouhinPS: true,
  nouhinBL: true,
  keppinPS: true,
  keppinBL: true,
  keppinKingaku: true,
  localSlipNo: true,
  orderNo: true,
  orderType: true,
  origin: false,
  keppin: false,
  biko: true,
  responsibility: true,
  reason: true,
  updateUser: true,
  updateDate: true,
  group1NM: true,
  tanaban: true,
  yoteiDte: true,
  yoteiBL: true,
}


export const headerColClasses = [
  {
    no: 'header_noBorder_no',
    centerCD: 'header',
    centerNM: 'header',
    itemCD: 'header',
    itemNM: 'header',
    janCD: 'header',
    makerCD: 'header',
    makerNM: 'header',
    categoryCD: 'header',
    categoryNM: 'header',
    blIrisu: 'header',
    csIrisu: 'header',
    teika: 'header',
    shubaiDate: 'header',
    teiban: 'header',
    tokuisakiCD: 'header',
    tokuisakiNM: 'header',
    torihikisakiCD: 'header',
    slipNo: 'header',
    detailNo: 'header',
    syukkaDate: 'header',
    nouhinDate: 'header',
    jutyuPS: 'header',
    jutyuBL: 'header',
    nouhinPS: 'header',
    nouhinBL: 'header',
    keppinPS: 'header',
    keppinBL: 'header',
    keppinKingaku: 'header',
    localSlipNo: 'header',
    orderNo: 'header',
    orderType: 'header',
    origin: 'header_keppin',
    keppin: 'header_keppin',
    biko: 'header_keppin',
    responsibility: 'header_keppin',
    reason: 'header_keppin',
    updateUser: 'header',
    updateDate: 'header',
    group1NM: 'header',
    tanaban: 'header',
    yoteiDte: 'header',
    yoteiBL: 'header',
  },
  {
    no: 'header_no',
    centerCD: 'header',
    centerNM: 'header',
    itemCD: 'header',
    itemNM: 'header',
    janCD: 'header',
    makerCD: 'header',
    makerNM: 'header',
    categoryCD: 'header',
    categoryNM: 'header',
    blIrisu: 'header',
    csIrisu: 'header',
    teika: 'header',
    shubaiDate: 'header',
    teiban: 'header',
    tokuisakiCD: 'header',
    tokuisakiNM: 'header',
    torihikisakiCD: 'header',
    slipNo: 'header',
    detailNo: 'header',
    syukkaDate: 'header',
    nouhinDate: 'header',
    jutyuPS: 'header',
    jutyuBL: 'header',
    nouhinPS: 'header',
    nouhinBL: 'header',
    keppinPS: 'header',
    keppinBL: 'header',
    keppinKingaku: 'header',
    localSlipNo: 'header',
    orderNo: 'header',
    orderType: 'header',
    origin: 'header_keppin',
    keppin: 'header_keppin',
    biko: 'header_keppin',
    responsibility: 'header_keppin',
    reason: 'header_keppin',
    updateUser: 'header',
    updateDate: 'header',
    group1NM: 'header',
    tanaban: 'header',
    yoteiDte: 'header',
    yoteiBL: 'header',
  },
];

export const colWidths =
{
  no: 40,
  centerCD: 50,
  centerNM: 140,
  itemCD: 100,
  itemNM: 240,
  janCD: 120,
  makerCD: 80,
  makerNM: 140,
  categoryCD: 100,
  categoryNM: 140,
  blIrisu: 60,
  csIrisu: 60,
  teika: 60,
  shubaiDate: 60,
  teiban: 60,
  tokuisakiCD: 90,
  tokuisakiNM: 200,
  torihikisakiCD: 90,
  slipNo: 90,
  detailNo: 80,
  syukkaDate: 80,
  nouhinDate: 80,
  jutyuPS: 80,
  jutyuBL: 80,
  nouhinPS: 80,
  nouhinBL: 80,
  keppinPS: 80,
  keppinBL: 80,
  keppinKingaku: 80,
  localSlipNo: 80,
  orderNo: 80,
  orderType: 80,
  origin: 120,
  keppin: 200,
  biko: 200,
  responsibility: 100,
  reason: 200,
  updateUser: 80,
  updateDate: 120,
  group1NM: 240,
  tanaban: 100,
  yoteiDte: 80,
  yoteiBL: 80,
};

export const colDataType =
{
  no: { type: 'text', readOnly: true, },
  centerCD: { type: 'text', readOnly: true, className: 'htCenter', },
  centerNM: { type: 'text', readOnly: true, },
  itemCD: { type: 'text', readOnly: true, className: 'htCenter', },
  itemNM: { type: 'text', readOnly: true, },
  janCD: { type: 'text', readOnly: true, },
  makerCD: { type: 'text', readOnly: true, className: 'htCenter', },
  makerNM: { type: 'text', readOnly: true, },
  categoryCD: { type: 'text', readOnly: true, className: 'htCenter', },
  categoryNM: { type: 'text', readOnly: true, },
  blIrisu: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  csIrisu: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  teika: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  shubaiDate: { type: 'text', readOnly: true, className: 'htCenter', },
  teiban: { type: 'text', readOnly: true, className: 'htCenter', },
  tokuisakiCD: { type: 'text', readOnly: true, className: 'htLeft', },
  tokuisakiNM: { type: 'text', readOnly: true, },
  torihikisakiCD: { type: 'text', readOnly: true, className: 'htLeft', },
  slipNo: { type: 'text', readOnly: true, },
  detailNo: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  syukkaDate: { type: 'text', readOnly: true, className: 'htCenter', },
  nouhinDate: { type: 'text', readOnly: true, className: 'htCenter', },
  jutyuPS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, className: 'htRight', },
  jutyuBL: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.#', }, className: 'htRight', },
  nouhinPS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, className: 'htRight', },
  nouhinBL: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.#', }, className: 'htRight', },
  keppinPS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, className: 'htRight', },
  keppinBL: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.#', }, className: 'htRight', },
  keppinKingaku: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, className: 'htRight', },
  localSlipNo: { type: 'text', readOnly: true, },
  orderNo: { type: 'text', readOnly: true, },
  orderType: { type: 'text', readOnly: true, },
  origin: { type: 'dropdown', source: originKbnList.map(data => data.code === 'all' ? '' : data.name), readOnly: false, className: 'htLeft' },
  keppin: { type: 'dropdown', source: [], readOnly: false, },
  biko: { type: 'text', },
  responsibility: { type: 'text', readOnly: true, },
  reason: { type: 'text', readOnly: true, },
  updateUser: { type: 'text', readOnly: true, className: 'htCenter', },
  updateDate: { type: 'text', readOnly: true, className: 'htCenter', },
  group1NM: { type: 'text', readOnly: true, },
  tanaban: { type: 'text', readOnly: true, },
  yoteiDte: { type: 'text', readOnly: true, className: 'htCenter', },
  yoteiBL: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, className: 'htRight', },
};


//列定義情報（動的パラメータ）
interface MainPageColRowModelInterface {
  //行固定
  fixedRowsTop: number;
  //列固定
  fixedColumnsLeft: number,

  //テーブルの各列のキー
  colKeys: string[],
}

export class MainPageColRowModel implements MainPageColRowModelInterface {
  //行固定
  fixedRowsTop: number;
  //列固定
  fixedColumnsLeft: number;
  //テーブルの各列のキー
  colKeys: string[];
  //ヘッダー
  nestedHeaders; //any[][]
  nestedHeadersBackup; //any[][]
  //ヘッダー区切り
  nestedHeadersGroupIndex: number[];
  //テーブルの各列の逆引き用マップ
  colKeysMap;

  constructor(param: MainPageColRowModelInterface) {
    this.fixedRowsTop = param.fixedRowsTop;
    this.fixedColumnsLeft = param.fixedColumnsLeft;
    this.colKeys = param.colKeys;

    //テーブルの各列のキー逆引き
    const colKeysMap = {};
    param.colKeys.forEach((colKey, index) => {
      colKeysMap[colKey] = index;
    });
    this.colKeysMap = colKeysMap;

    //nestedHeaders初期化
    this.initNestedHeaders();
  }

  //nestedHeaders初期化
  initNestedHeaders() {
    //nestedHeaders
    const nestedHeaders = [];
    const nestedHeadersGroupIndex = [];
    // const customBorders = [];
    headersCol.forEach((header, index) => {
      const lastHeader = index == headersCol.length - 1;
      const nestedHeader = [];

      let colspan = 0;
      let preHeaderVal = null;
      this.colKeys.forEach((colKey) => {
        const headerVal = header[colKey];
        if (lastHeader) {
          nestedHeader.push(headerVal);
        }
        else {
          if (preHeaderVal !== null && preHeaderVal != headerVal) {
            nestedHeader.push(colspan == 1 ? preHeaderVal : { label: preHeaderVal, colspan: colspan });
            colspan = 0;
          }
          colspan++;
          preHeaderVal = headerVal;
        }
        //0行目でグループ
        if (index == 0) {
          nestedHeadersGroupIndex.push(colspan - 1);
        }
      });
      if (!lastHeader && colspan > 0) {
        nestedHeader.push(colspan == 1 ? preHeaderVal : { label: preHeaderVal, colspan: colspan });
      }

      nestedHeaders.push(nestedHeader);
    });

    this.nestedHeaders = nestedHeaders;
    this.nestedHeadersBackup = nestedHeaders.map(nestedHeadersSub => [...nestedHeadersSub]);
    this.nestedHeadersGroupIndex = nestedHeadersGroupIndex;
  }

  //function
  keyFromCol(column: number): string {
    return this.colKeys[column];
  }
  keyFromCols(columns: number[]): string[] {
    const vals: string[] = [];
    columns.forEach(column => vals.push(this.colKeys[column]))
    return vals;
  }
  colFromKey(colKey: string): number {
    return this.colKeysMap[colKey];
  }
  colFromKeys(colKeys: string[]): number[] {
    const vals: number[] = [];
    colKeys.forEach(colKey => vals.push(this.colKeysMap[colKey]))
    return vals;
  }

}

//MainPage用の列モデル
export const mainPageColRowModelDefault: MainPageColRowModel = new MainPageColRowModel({
  //行固定
  fixedRowsTop: 0,
  //列固定
  fixedColumnsLeft: 7,

  //テーブルの各列のキー
  colKeys: [
    ...headerColKeys,
  ],
});

