import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/assets/commontype/CodeName";

import { DiffData, RowData, SaveInfoData, getOriginKbnCD, getOriginKbnName, ErrorInfoData, originKbnList } from '@/store/mainpage/mainPageCommon';
import * as mainPageRetrieve from "@/assets/apitype/mainPageRetrieve";
import { MainPageColRowModel, colDataType, } from "@/components/mainpage/MainPageTableModel";
import * as compareUtil from "@/util/compareUtil";
import * as arrayutil from "@/util/arrayUtil";
import * as editorUtil from "@/util/editorUtil";
import * as calcUtil from "@/util/calcUtil";
import * as momentUtil from "@/util/momentUtil";
import moment, { Moment } from 'moment';

import { ColumnConditions } from 'handsontable/plugins/filters';

//0.00フォーマット
const formatterP00 = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
//0,0フォーマット
const formatterN0 = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 });


export type RowInfo = {
  row: number,
  data: RowData,
  dataIndex: number,

  no: string,
}

export interface EditData {
  row: number,
  col: number,
  rowKey?: string,
  value: string | number | object | null,
  relatedValues?: { key: string, value: string | number | object | null }[],
  uuid?: string,
}

//Page State
export type MainPageTmpState = {
  editing: boolean,

  bumonList: CodeName[],  //部門
  centerList: CodeName[], //倉庫
  makerList: CodeName[],  //メーカー
  group1List: CodeName[],  //企業グループ1
  tokuisaki1List: CodeName[],  //得意先本部
  tokuisaki2List: CodeName[],  //得意先支店
  reasonList: CodeName[],  //欠品理由
  originKeppinList: CodeName[],  //

  progress: Record<string, unknown>,

  retrieveParam: mainPageRetrieve.RequestParam,  //検索条件

  retrievedParam: mainPageRetrieve.RequestParam,  //検索条件(検索済み)
  retrievedEditSeq: number,  //取得済みEdiSeq

  centerCDs: string[],
  itemCDs: string[],

  rowInfos: RowInfo[],
  // allDatas: RowData[],
  datas: RowData[],
  rows: any[][],
  mergeCells: { row: number, col: number, rowspan: number, colspan: number }[],
  historyDatas: RowData[],

  suspendEditDatas: EditData[],

  tableVer_updateData: number,
  tableVer_updateData_rows: number[],
  tableVer_updateSettting: number,
  tableVer_scrollDefault: number,

  saveVer: number,
  saveSeq: number,
  saveQueue: SaveInfoData[],

  loadVerCorrect: number,
  loadVerRefrect: number,
  loadSeq: number,
  loadQueue: DiffData[],

  errorMessage: string | null,

  conditionsStack: ColumnConditions[] | null,
  indexedValues: any[] | null,

  hiddenRows: number[],
  //共有URL
  shareURL: string,
};

export const initialState: MainPageTmpState = {
  editing: false,
  bumonList: [],
  centerList: [],
  makerList: [],
  group1List: [],
  tokuisaki1List: [],
  tokuisaki2List: [],
  reasonList: [],
  originKeppinList: [],

  progress: {},

  retrieveParam: null,  //検索条件

  retrievedParam: null,
  retrievedEditSeq: 0,  //取得済みEdiSeq

  centerCDs: [],
  itemCDs: [],

  rowInfos: [],
  // allDatas: [],
  datas: [],
  rows: [],
  mergeCells: null,
  historyDatas: [],

  suspendEditDatas: [],

  tableVer_updateData: 0,
  tableVer_updateData_rows: [],
  tableVer_updateSettting: 0,
  tableVer_scrollDefault: 0,

  saveVer: 0,
  saveSeq: 0,
  saveQueue: [],

  loadVerCorrect: 0,
  loadVerRefrect: 0,
  loadSeq: 0,
  loadQueue: [],

  errorMessage: null,

  conditionsStack: [],
  indexedValues: [],

  hiddenRows: [],
  //共有URL
  shareURL: '',
};

//Page Slice
export type MainPageTmpReducer = {
  setEditingStart: (state: MainPageTmpState) => void,
  setEditingEnd: (state: MainPageTmpState) => void,
  putProgress: (state: MainPageTmpState, action: PayloadAction<string>) => void,
  removeProgress: (state: MainPageTmpState, action: PayloadAction<string>) => void,
  setErrorMessage: (state: MainPageTmpState, action: PayloadAction<string>) => void,
  setBumonList: (state: MainPageTmpState, action: PayloadAction<CodeName[]>) => void,
  setCenterList: (state: MainPageTmpState, action: PayloadAction<CodeName[]>) => void,
  setMakerList: (state: MainPageTmpState, action: PayloadAction<CodeName[]>) => void,
  setGroup1List: (state: MainPageTmpState, action: PayloadAction<CodeName[]>) => void,
  setTokuisaki1List: (state: MainPageTmpState, action: PayloadAction<CodeName[]>) => void,
  setTokuisaki2List: (state: MainPageTmpState, action: PayloadAction<CodeName[]>) => void,
  setReasonList: (state: MainPageTmpState, action: PayloadAction<CodeName[]>) => void,
  setOriginKeppinList: (state: MainPageTmpState, action: PayloadAction<CodeName[]>) => void,
  startRetrieve: (state: MainPageTmpState, action: PayloadAction<{
    retrieveParam: mainPageRetrieve.RequestParam,  //検索条件
  }>) => void,
  setRetrievedParam: (state: MainPageTmpState, action: PayloadAction<mainPageRetrieve.RequestParam>) => void,
  initRetrievedEditSeq: (state: MainPageTmpState, action: PayloadAction<number>) => void,
  updateRetrievedEditSeq: (state: MainPageTmpState, action: PayloadAction<number>) => void,
  searchedTargets: (state: MainPageTmpState, action: PayloadAction<{
    param: mainPageRetrieve.RequestParam,
    datas: RowData[],
    colRowModel: MainPageColRowModel,
    sort: { key: string, asc: boolean },
    showSubTotal: 'なし' | 'あり' | 'のみ',
  }>) => void,
  searchedHistory: (state: MainPageTmpState, action: PayloadAction<{
    datas: RowData[],
  }>) => void,
  clearTable: (state: MainPageTmpState, action: PayloadAction<{}>) => void,
  refreshTable: (state: MainPageTmpState, action: PayloadAction<{
    colRowModel: MainPageColRowModel,
    sort: { key: string, asc: boolean },
    showSubTotal: 'なし' | 'あり' | 'のみ',
  }>) => void,
  editRowDatas: (state: MainPageTmpState, action: PayloadAction<{ colRowModel: MainPageColRowModel, editDatas: EditData[], showSubTotal: 'なし' | 'あり' | 'のみ', suspendCheck: boolean }>) => void,
  afterSave: (state: MainPageTmpState, action: PayloadAction<{ dataIndex: number, uuid: string, }>) => void,
  refrectLoadDatas: (state: MainPageTmpState, action: PayloadAction<{ colRowModel: MainPageColRowModel, showSubTotal: 'なし' | 'あり' | 'のみ', }>) => void,
  execSaveQueue: (state: MainPageTmpState) => void,
  addSaveQueue: (state: MainPageTmpState, action: PayloadAction<SaveInfoData[]>) => void,
  removeSaveQueue: (state: MainPageTmpState, action: PayloadAction<SaveInfoData[]>) => void,
  execLoadQueueCorrect: (state: MainPageTmpState) => void,
  execLoadQueueRefrect: (state: MainPageTmpState) => void,
  addLoadQueue: (state: MainPageTmpState, action: PayloadAction<DiffData[]>) => void,
  removeLoadQueue: (state: MainPageTmpState, action: PayloadAction<DiffData[]>) => void,
  setConditionsStack: (state: MainPageTmpState, action: PayloadAction<ColumnConditions[]>) => void,
  setIndexedValues: (state: MainPageTmpState, action: PayloadAction<any[]>) => void,
  clearSuspendEditDatas: (state: MainPageTmpState) => void,
  setShareURL: (state: MainPageTmpState, action: PayloadAction<string>) => void,
}

const createReducerContent = (): MainPageTmpReducer => {
  return {
    setEditingStart(state: MainPageTmpState) {
      console.log('store.setEditingStart');
      state.editing = true;
      state.retrievedEditSeq = 0;

      state.retrieveParam = null;  //検索条件
    },
    setEditingEnd(state: MainPageTmpState) {
      console.log('store.setEditingEnd');

      state.editing = false;
      state.retrievedEditSeq = 0;

      state.retrieveParam = null;  //検索条件

      state.progress = {};
      state.retrievedParam = null;

      state.centerCDs = [];
      state.itemCDs = [];

      state.rowInfos = [];
      state.rows = [];
      state.mergeCells = null;

      state.conditionsStack = [];
      state.indexedValues = [];

      // errorMessage: null,

      //テーブル更新
      state.tableVer_updateSettting = state.tableVer_updateSettting + 1;  //カウントアップしテーブルを再構築対象とする (updateSetting)

    },
    putProgress(state: MainPageTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = { ...state.progress };
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state: MainPageTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if (key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },
    setErrorMessage(state: MainPageTmpState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    setBumonList(state: MainPageTmpState, action: PayloadAction<CodeName[]>) {
      state.bumonList = action.payload;
    },
    setCenterList(state: MainPageTmpState, action: PayloadAction<CodeName[]>) {
      state.centerList = action.payload;
    },
    setMakerList(state: MainPageTmpState, action: PayloadAction<CodeName[]>) {
      state.makerList = action.payload;
    },
    setGroup1List(state: MainPageTmpState, action: PayloadAction<CodeName[]>) {
      state.group1List = action.payload;
    },
    setTokuisaki1List(state: MainPageTmpState, action: PayloadAction<CodeName[]>) {
      state.tokuisaki1List = action.payload;
    },
    setTokuisaki2List(state: MainPageTmpState, action: PayloadAction<CodeName[]>) {
      state.tokuisaki2List = action.payload;
    },
    setReasonList(state: MainPageTmpState, action: PayloadAction<CodeName[]>) {
      state.reasonList = action.payload;
    },
    setOriginKeppinList(state: MainPageTmpState, action: PayloadAction<CodeName[]>) {
      state.originKeppinList = action.payload;
    },
    startRetrieve(state: MainPageTmpState, action: PayloadAction<{
      retrieveParam: mainPageRetrieve.RequestParam,  //検索条件
    }>) {
      state.retrieveParam = action.payload.retrieveParam;
    },

    setRetrievedParam(state: MainPageTmpState, action: PayloadAction<mainPageRetrieve.RequestParam>) {
      state.retrievedParam = action.payload;

    },
    initRetrievedEditSeq(state: MainPageTmpState, action: PayloadAction<number>) {
      console.log('store.initRetrievedEditSeq');
      state.retrievedEditSeq = action.payload;
    },
    updateRetrievedEditSeq(state: MainPageTmpState, action: PayloadAction<number>) {
      console.log('store.updateRetrievedEditSeq');
      state.retrievedEditSeq = action.payload;
    },

    searchedTargets(state: MainPageTmpState, action: PayloadAction<{
      param: mainPageRetrieve.RequestParam,
      datas: RowData[],
      colRowModel: MainPageColRowModel,
      sort: { key: string, asc: boolean },
      showSubTotal: 'なし' | 'あり' | 'のみ',
    }>
    ) {
      console.log('store.searchedTargets');
      const sort = action.payload.sort;
      const showSubTotal = action.payload.showSubTotal;

      let datas = action.payload.datas;
      const colRowModel = action.payload.colRowModel;

      // state.allDatas = [...datas];

      datas = parseData(datas);

      datas = makeDatas(state, datas);
      datas = checkDatas(state, datas);
      datas = doSort(state, datas, sort.key, sort.asc, showSubTotal);

      const rowInfos = convertRows(datas, colRowModel, showSubTotal);
      state.rowInfos = rowInfos.rowInfos;
      state.datas = datas;
      state.rows = rowInfos.rows;

      // const tableVer_updateData_rows = [];
      // rowInfos.rowInfos.forEach(rowInfo => {
      //   tableVer_updateData_rows.push(rowInfo.row);
      // });

      //テーブル更新
      // state.tableVer_updateData_rows = tableVer_updateData_rows;
      state.tableVer_updateSettting = state.tableVer_updateSettting + 1;  //カウントアップしテーブルを再構築対象とする (updateSetting)
    },
    searchedHistory(state: MainPageTmpState, action: PayloadAction<{
      datas: RowData[],
    }>
    ) {
      console.log('store.searchedHistory');

      let datas = action.payload.datas;
      datas = parseData(datas);
      datas = calcDatas(state, datas, "all");
      datas = resetRowNo(datas);

      state.historyDatas = datas;
    },
    clearTable(state: MainPageTmpState, action: PayloadAction<{}>) {
      console.log('clearTable');

      state.rowInfos = [];
      state.rows = [];
      state.mergeCells = null;
      //テーブル更新
      state.tableVer_updateSettting = state.tableVer_updateSettting + 1;  //カウントアップしテーブルを再構築対象とする (updateSetting)
    },
    refreshTable(state: MainPageTmpState, action: PayloadAction<{
      colRowModel: MainPageColRowModel,
      sort: { key: string, asc: boolean },
      showSubTotal: 'なし' | 'あり' | 'のみ',
    }>) {
      console.log('refreshTable');
      const sort = action.payload.sort;
      const showSubTotal = action.payload.showSubTotal;

      let datas = state.datas;
      const colRowModel = action.payload.colRowModel;

      // datas = parseData(datas);

      // const grouping1 = showSubTotal !== 'なし' ? 'item' : '';

      // datas = makeDatas(state, datas, sort, grouping1, true);
      datas = checkDatas(state, datas);
      datas = doSort(state, datas, sort.key, sort.asc, showSubTotal);

      const rowInfos = convertRows(datas, colRowModel, showSubTotal);
      state.rowInfos = rowInfos.rowInfos;
      state.datas = datas;
      state.rows = rowInfos.rows;

      //テーブル更新
      state.tableVer_updateSettting = state.tableVer_updateSettting + 1;  //カウントアップしテーブルを再構築対象とする (updateSetting)

    },
    editRowDatas(state: MainPageTmpState, action: PayloadAction<{
      colRowModel: MainPageColRowModel,
      editDatas: EditData[],
      showSubTotal: 'なし' | 'あり' | 'のみ',
      suspendCheck: boolean,
    }>) {
      console.log('store.editRowDatas');

      const isEdit = true;
      const colRowModel = action.payload.colRowModel;
      const editDatas = action.payload.editDatas;
      const showSubTotal = action.payload.showSubTotal;
      const suspendCheck = action.payload.suspendCheck;

      //編集処理本体
      fnc_editRowDatas(
        state,
        colRowModel,
        editDatas,
        isEdit,
        showSubTotal,
        suspendCheck
      );
    },
    afterSave(state: MainPageTmpState, action: PayloadAction<{
      dataIndex: number,
      uuid: string
    }>) {
      console.log('store.afterSave');

      const dataIndex = action.payload.dataIndex;
      const uuid = action.payload.uuid;
      let newDatas = [...state.datas];

      let data = newDatas[dataIndex];
      data.uuid = uuid;

      state.datas = newDatas;
    },
    refrectLoadDatas(state: MainPageTmpState, action: PayloadAction<{
      colRowModel: MainPageColRowModel,
      showSubTotal: 'なし' | 'あり' | 'のみ',
    }>) {
      console.log('store.refrectLoadDatas');
      const isEdit = false;
      const rowInfos = state.rowInfos;
      const colRowModel = action.payload.colRowModel;
      const showSubTotal = action.payload.showSubTotal;
      const diffDatas: DiffData[] = state.loadQueue;
      const editDatas: EditData[] = [];
      diffDatas.forEach(diffData => {
        const rowInfo = rowInfos.find(rowInfo => rowInfo.data.div == diffData.div && rowInfo.data.id == diffData.id);
        if (rowInfo) {
          const row = rowInfo.row;
          const col = colRowModel.colFromKey('origin');
          const newVal = getOriginKbnName(diffData['origin'], false);
          const relatedValues = [
            { key: 'updateDate', value: diffData['updateDate'], },
            { key: 'updateUser', value: diffData['updateUser'], },
          ];
          {
            const code = diffData['keppinCD'];
            const reason = state.reasonList.find(reason => reason.option1 == code);
            if (reason) {
              relatedValues.push({ key: 'keppin', value: code, });
              relatedValues.push({ key: 'responsibility', value: reason.name, });
            }
          }
          if(diffData['biko']){
            relatedValues.push({ key: 'keppin', value: diffData['biko'], });
          }
          editDatas.push({ row: row, col: col, value: newVal, relatedValues: relatedValues, uuid: diffData['uuid'], });
        }
        //キューから削除
        fnc_removeLoadQueue(state, [diffData]);
      });

      if (editDatas.length > 0) {
        //編集処理本体
        fnc_editRowDatas(
          state,
          colRowModel,
          editDatas,
          isEdit,
          showSubTotal
        );
      }
    },
    execSaveQueue(state: MainPageTmpState) {
      console.log('store.execSaveQueue');
      state.saveVer = state.saveVer + 1;  //カウントアップし登録処理を開始する
    },
    addSaveQueue(state: MainPageTmpState, action: PayloadAction<SaveInfoData[]>) {
      console.log('store.addSaveQueue');
      let saveDatas: SaveInfoData[] = action.payload;
      if (saveDatas.length > 0) {
        state.saveQueue = [...state.saveQueue, ...saveDatas];
        state.saveVer = state.saveVer + 1;  //カウントアップし登録処理を開始する
      }
    },
    removeSaveQueue(state: MainPageTmpState, action: PayloadAction<SaveInfoData[]>) {
      console.log('store.removeSaveQueue');
      const saveInfos: SaveInfoData[] = action.payload;
      fnc_removeSaveQueue(state, saveInfos);
    },
    execLoadQueueCorrect(state: MainPageTmpState) {
      console.log('store.execLoadQueueCorrect');
      state.loadVerCorrect = state.loadVerCorrect + 1;  //カウントアップし読込処理を開始する
    },
    execLoadQueueRefrect(state: MainPageTmpState) {
      console.log('store.execLoadQueueRefrect');
      state.loadVerRefrect = state.loadVerRefrect + 1;  //カウントアップし読込処理を開始する
    },
    addLoadQueue(state: MainPageTmpState, action: PayloadAction<DiffData[]>) {
      console.log('store.addLoadQueue');
      let loadDatas: DiffData[] = action.payload;
      loadDatas = loadDatas.map(loadData => {
        if (!loadData.seq) {
          loadData.seq = state.loadSeq + 1;  //カウントアップ
        }
        return loadData;
      });
      if (loadDatas.length > 0) {
        state.loadQueue = [...state.loadQueue, ...loadDatas];
        // state.loadVerRefrect = state.loadVerRefrect+1;  //カウントアップし登録処理を開始する
      }
    },
    removeLoadQueue(state: MainPageTmpState, action: PayloadAction<DiffData[]>) {
      console.log('store.removeLoadQueue');
      const loadInfos: DiffData[] = action.payload;
      fnc_removeLoadQueue(state, loadInfos);
    },
    setConditionsStack(state: MainPageTmpState, action: PayloadAction<ColumnConditions[]>) {
      state.conditionsStack = action.payload;
    },
    setIndexedValues(state: MainPageTmpState, action: PayloadAction<any[]>) {
      state.indexedValues = action.payload;
    },
    clearSuspendEditDatas(state: MainPageTmpState) {
      state.suspendEditDatas = [];
    },
    setShareURL(state: MainPageTmpState, action: PayloadAction<string>) {
      state.shareURL = action.payload;
    },
  }
};

const fnc_editRowDatas = (
  state: MainPageTmpState,
  colRowModel: MainPageColRowModel,
  editDatas: EditData[],
  isEdit: boolean,
  showSubTotal: 'なし' | 'あり' | 'のみ',
  suspendCheck: boolean = false
) => {
  console.log('store.fnc_editRowDatas');

  //登録用のキュー
  const addSaveQueue: SaveInfoData[] = [];
  const saveQueueMap: Map<number, SaveInfoData> = new Map<number, SaveInfoData>();
  let newDatas = state.datas.map(d => { return { ...d } });
  // const tableVer_updateData_rows = [];
  let suspend = false;

  const setValue = (key: string, data, value): boolean => {
    switch (key) {
      case "no":
        break;
      case "origin":
        if (!originKbnList.find(d => d.name == value) && value !== null) {
          throw new Error(`'${value}'はリストにない`);
        }
        data.origin = value ? '' + value : null;
        data.originCD = value ? getOriginKbnCD('' + value) : null;
        break;
      case "keppin":
        const reason = state.reasonList.find(reason => reason.option1 == value);
        if (!reason && value !== null) {
          throw new Error(`'${value}'はリストにない`);
        }
        if (reason || value === null) {
          suspend = suspend || (data.keppinCD && data.keppinCD !== '10'); //設定済み、かつ 10:未入力 以外
          data.keppinCD = value ? '' + value : null;
          data.keppinNM = value ? reason.option2 : null;
          data.keppin = data.keppinCD ? data.keppinCD + ':' + data.keppinNM : null;
        }
        break;
      default:
        data[key] = value;
        break;
    }
    return suspend;
  }
  const editDataContent = (rowInfo: RowInfo, row: number, col: number, key: string, value: string | number | boolean | object | null) => {
    if (!(row >= 0) || (!key && !(col >= 0))) {
      return;
    }
    if (!key) {
      key = colRowModel.keyFromCol(col);
    }
    if (!(col >= 0)) {
      col = colRowModel.colFromKey(key);
    }

    //parse
    const dataType = colDataType[key];
    value = editorUtil.parseValue(value, dataType.type,
      dataType.type == 'numeric' ? dataType.numericFormat.pattern :
        dataType.type == 'date' ? dataType.dateFormat :
          null);

    let data = newDatas[rowInfo.dataIndex];

    // //allも書き換える
    // const allData = state.allDatas.find(allData =>
    //   allData.ksbucd == data.ksbucd &&
    //   allData.kskacd == data.kskacd &&
    //   allData.kstcd1 == data.kstcd1 &&
    //   allData.kstcd2 == data.kstcd2 &&
    //   allData.kstdno == data.kstdno &&
    //   allData.ksline == data.ksline &&
    //   allData.ksnohn == data.ksnohn &&
    //   allData.kssdat == data.kssdat &&
    //   allData.ksjuno == data.ksjuno
    // );
    setValue(key, data, value);
    // if (allData) {
    //   setValue(key, allData, value);
    // }
  };

  try {
    editDatas.forEach((editData) => {
      const key = colRowModel.keyFromCol(editData.col);
      const rowInfo = state.rowInfos[editData.row];
      const oldData = { ...newDatas[rowInfo.dataIndex] };

      editDataContent(rowInfo, editData.row, editData.col, key, editData.value);
      //関連データの更新
      if (editData.relatedValues) {
        editData.relatedValues.forEach(relatedValue => {
          editDataContent(rowInfo, editData.row, null, relatedValue.key, relatedValue.value);
        });
      }
      let data = newDatas[rowInfo.dataIndex];
      // const allData = state.allDatas.find(allData =>
      //   allData.ksbucd == data.ksbucd &&
      //   allData.kskacd == data.kskacd &&
      //   allData.kstcd1 == data.kstcd1 &&
      //   allData.kstcd2 == data.kstcd2 &&
      //   allData.kstdno == data.kstdno &&
      //   allData.ksline == data.ksline &&
      //   allData.ksnohn == data.ksnohn &&
      //   allData.kssdat == data.kssdat &&
      //   allData.ksjuno == data.ksjuno
      // );
      if (editData.uuid) {
        data.uuid = editData.uuid;
        // //allも書き換える
        // if (allData) {
        //   allData.uuid = editData.uuid;
        // }
      }
      const list = ['origin', 'keppin'] ;
      let editState: string[] = data.editState ? [...data.editState] : [];
      list.forEach(key => {
        if (oldData[key] != data[key]) {
          if (isEdit && editState.includes(key)) {
            editState = editState.filter(d => d != key);
          } else if (!isEdit && !editState.includes(key)) {
            editState.push(key);
          }
        }
      });
      data.editState = editState;
      // //allも書き換える
      // if (allData) {
      //   allData.editState = editState;
      // }

      data = checkData(state, data);

      // tableVer_updateData_rows.push(editData.row);

      //キューに追加
      if (isEdit && data.TP === 'item') {
        let queue: SaveInfoData = saveQueueMap.get(rowInfo.dataIndex);
        if(!queue) {
          state.saveSeq = state.saveSeq + 1;  //カウントアップ
          const queue: SaveInfoData = {
            seq: state.saveSeq,

            dataIndex: rowInfo.dataIndex,
            uuid: data.uuid,
            //key
            div: data.div,
            id: data.id,

            //value
            originCD: data.originCD,
            keppinCD: data.keppinCD,
            biko: data.biko,
            updateDate: data.updateDate,
          };
          addSaveQueue.push(queue);
          saveQueueMap.set(rowInfo.dataIndex, queue);
        }
        else {
          queue.originCD = data.originCD;
          queue.keppinCD = data.keppinCD;
          queue.biko = data.biko;
          queue.updateDate = data.updateDate;
        }
      }
    });
  } catch (error) {
    return;
  }
  if (suspend && isEdit && suspendCheck) { //保留対象あり、かつ自画面での編集、かつ保留対象チェックあり
    state.suspendEditDatas = [...editDatas];
    return;
  }

  const grouping1 = 'item';
  newDatas = checkGrouping1(newDatas, grouping1);

  // newDatas = checkDatas(newDatas);
  // newDatas = doSort(newDatas, sorts, state.adms);
  const rowInfos = convertRows(newDatas, colRowModel, showSubTotal);
  state.rowInfos = rowInfos.rowInfos;
  state.datas = newDatas;
  state.rows = rowInfos.rows;

  // state.tableVer_updateData_rows = tableVer_updateData_rows;
  state.tableVer_updateData = state.tableVer_updateData + 1;  //カウントアップしテーブルを再描写対象とする (updateData)

  //キューに追加
  state.saveQueue = [...state.saveQueue, ...addSaveQueue];
  state.saveVer = state.saveVer + 1;  //カウントアップし登録処理を開始する
};
const fnc_removeSaveQueue = (state, saveInfos: SaveInfoData[]) => {
  console.log('fnc_removeSaveQueue');

  if (!saveInfos || !saveInfos.length) {
    return;
  }
  const saveInfosSeq: number[] = saveInfos.map(saveInfo => saveInfo.seq);
  state.saveQueue = state.saveQueue.filter(saveInfo => saveInfosSeq.indexOf(saveInfo.seq) == -1);
};
const fnc_removeLoadQueue = (state, loadInfos: DiffData[]) => {
  console.log('fnc_removeLoadQueue');

  if (!loadInfos || !loadInfos.length) {
    return;
  }

  const loadInfosSeq: number[] = loadInfos.map(loadInfo => loadInfo.seq);
  state.loadQueue = state.loadQueue.filter(loadInfo => loadInfosSeq.indexOf(loadInfo.seq) == -1);
};

//数値のパース等(数値が文字列で返ってくる)
const parseData = (datas: RowData[]): RowData[] => {
  let tmp: number;
  //set No.
  datas.forEach((data) => {
    if (typeof data.id === 'string') data.id = parseInt(data.id);
    if (typeof data.detailNo === 'string') data.detailNo = parseInt(data.detailNo);
    if (typeof data.jutyuPS === 'string') data.jutyuPS = (tmp = parseInt(data.jutyuPS)) ? tmp : null;
    if (typeof data.nouhinPS === 'string') data.nouhinPS = (tmp = parseInt(data.nouhinPS)) ? tmp : null;
    if (typeof data.keppinPS === 'string') data.keppinPS = (tmp = parseInt(data.keppinPS)) ? tmp : null;
    if (typeof data.keppinKingaku === 'string') data.keppinKingaku = parseInt(data.keppinKingaku);
    if (typeof data.blIrisu === 'string') data.blIrisu = parseInt(data.blIrisu);
    if (typeof data.csIrisu === 'string') data.csIrisu = parseInt(data.csIrisu);
    if (typeof data.yoteiBL === 'string') data.yoteiBL = (tmp = parseInt(data.yoteiBL)) ? tmp : null;

    data.jutyuBL = data.jutyuPS && data.blIrisu ? (Math.floor(data.jutyuPS / data.blIrisu * 10) / 10) : null;
    data.nouhinBL = data.nouhinPS && data.blIrisu ? (Math.floor(data.nouhinPS / data.blIrisu * 10) / 10) : null;
    data.keppinBL = data.keppinPS && data.blIrisu ? (Math.floor(data.keppinPS / data.blIrisu * 10) / 10) : null;

    if (data.originCD !== undefined) data.origin = getOriginKbnName(data.originCD, false);

  });
  return datas;
}

const makeDatas = (state, datas: RowData[]): RowData[] => {
  const grouping1 = 'item';

  datas = calcGrouping1(datas, grouping1); //小計行の作成
  datas = calcDatas(state, datas, "all"); //計算項目の計算
  datas = checkGrouping1(datas, grouping1);

  return datas;
};
//小計行作成
const calcGrouping1 = (datas: RowData[], grouping1: 'item' | string): RowData[] => {
  const targetMap = {};
  // datas = datas.filter(data => data.TP != "grouping1"); //小計行の削除

  //set No.
  datas.forEach((data) => {
    if (data.TP == "item") {
      const GCD1 = getGroupingCD(grouping1, data);
      const tanaban = grouping1 != 'item' ? '' : data?.tanaban?.trim() ? data.tanaban.trim() : '';
      let target: RowData = targetMap[GCD1];
      if (!target) {
        target = {
          TP: "grouping1",
          centerCD: grouping1 != 'item' ? '' : data.centerCD,
          centerNM: grouping1 != 'item' ? '' : data.centerNM,
          itemCD: grouping1 != 'item' ? '' : data.itemCD,
          itemNM: grouping1 != 'item' ? '' : data.itemNM,
          janCD: grouping1 != 'item' ? '' : data.janCD,
          makerCD: grouping1 != 'item' ? '' : data.makerCD,
          makerNM: grouping1 != 'item' ? '' : data.makerNM,
          categoryCD: grouping1 != 'item' ? '' : data.categoryCD,
          categoryNM: grouping1 != 'item' ? '' : data.categoryNM,
          blIrisu: grouping1 != 'item' ? null : data.blIrisu,
          csIrisu: grouping1 != 'item' ? null : data.csIrisu,
          teika: grouping1 != 'item' ? '' : data.teika,
          shubaiDate: grouping1 != 'item' ? '' : data.shubaiDate,
          teiban: grouping1 != 'item' ? '' : data.teiban,
          tanabans: [tanaban],
        };
        targetMap[GCD1] = target;
      }
      else {
        const tanabans = [...targetMap[GCD1].tanabans, tanaban];
        targetMap[GCD1] = {
          ...targetMap[GCD1],
          tanabans: tanabans,
        };
      }

      calcTotalSub(targetMap[GCD1], data);
    }
  });
  Object.keys(targetMap).forEach(GCD1 => {
    targetMap[GCD1] = {
      ...targetMap[GCD1],
      tanaban: targetMap[GCD1].tanabans
        .filter(tanaban => tanaban)
        .filter((tanaban, index, self) => self.indexOf(tanaban) === index) //重複削除
        .join(', '),
    };
  });

  //あとのソートのために、上に入れる。
  const newDatas = [];
  Object.keys(targetMap).forEach(GCD1 => newDatas.push(targetMap[GCD1]));
  // console.log(newDatas);
  return [...newDatas, ...datas];
}

//計算
const calcDatas = (state, datas: RowData[], targetTP: "all" | "grouping1" | "item"): RowData[] => {

  datas.forEach((data) => {
    if (targetTP == "all" || data.TP == targetTP) {
      //ここに貼り付け
      if (data.keppinCD) {
        const reason = state.reasonList.find(reason => reason.option1 == data.keppinCD);
        data.keppinNM = reason ? reason.option2 : '';
      }
      data.keppin = data.keppinCD ? data.keppinCD + ':' + data.keppinNM : null;
    }
  });
  return datas;
}
//小計行作成
const calcTotalSub = (total: RowData, data: RowData, key?: string[]) => {
  total.jutyuPS = calcUtil.plus(total.jutyuPS, data.jutyuPS);
  total.jutyuBL = calcUtil.plus(total.jutyuBL, data.jutyuBL);
  total.nouhinPS = calcUtil.plus(total.nouhinPS, data.nouhinPS);
  total.nouhinBL = calcUtil.plus(total.nouhinBL, data.nouhinBL);
  total.keppinPS = calcUtil.plus(total.keppinPS, data.keppinPS);
  total.keppinBL = calcUtil.plus(total.keppinBL, data.keppinBL);
  total.keppinKingaku = calcUtil.plus(total.keppinKingaku, data.keppinKingaku);

  total.yoteiDte = !!data.yoteiDte && (!total.yoteiDte || total.yoteiDte > data.yoteiDte) ? data.yoteiDte : total.yoteiDte;
  total.yoteiBL = !!data.yoteiBL && (!total.yoteiBL || total.yoteiBL < data.yoteiBL) ? data.yoteiBL : total.yoteiBL;
}

//チェック
const checkDatas = (state, datas: RowData[]): RowData[] => {
  console.log('store.checkDatas');
  return datas.map(data => checkData(state, data));
}

export const checkData = (state, data: RowData): RowData => {
  // const errorInfo: ErrorInfoData = {};
  // if (!data.errorInfo) {
  //   data.errorInfo = {};
  // }

  //TODO:エラー判定
  // if (!data.originCD) {
  //   errorInfo.origin = 'が指定されています';
  // }
  // if (data.originCD) {
  //   const reasonCDs = state.originKeppinList.filter(d => d.option1 == data.originCD).map(data => data.option2);
  //   const reason = state.reasonList.find(reason => reason.option1 == data.keppinCD);
  //   if (!reasonCDs.includes(reason?.option1)) {
  //     errorInfo.keppin = 'が指定されています';
  //   }
  // }

  // data.errorInfo = errorInfo;

  return data;
}

const checkGrouping1 = (datas: RowData[], grouping1: 'item' | string): RowData[] => {
  console.log('store.checkGrouping1');
  const targets = datas.filter(data => data.TP === "grouping1");
  targets.forEach(target => {
    const index = datas.findIndex(data => data.TP === 'grouping1' && data.centerCD === target.centerCD && data.itemCD === target.itemCD);
    const targetDatas = datas.filter(data => data.TP == "item" && getGroupingCD(grouping1, target) == getGroupingCD(grouping1, data));
    const origins = targetDatas.filter((value, index, self) => self.findIndex(data => data.originCD == value.originCD) === index);
    const keppins = targetDatas.filter((value, index, self) => self.findIndex(data => data.keppinCD == value.keppinCD) === index);
    const bikos = targetDatas.filter((value, index, self) => self.findIndex(data => data.biko == value.biko) === index);

    if (origins.length === 1) {
      datas[index] = { ...datas[index], originCD: origins[0].originCD, origin: origins[0].origin };
    } else {
      datas[index] = { ...datas[index], originCD: null, origin: null };
    }

    if (keppins.length === 1) {
      datas[index] = { ...datas[index], keppin: keppins[0].keppin, keppinCD: keppins[0].keppinCD, keppinNM: keppins[0].keppinNM };
    } else {
      datas[index] = { ...datas[index], keppin: null, keppinCD: null, keppinNM: null };
    }

    if (bikos.length === 1) {
      datas[index] = { ...datas[index], biko: bikos[0].biko };
    } else {
      datas[index] = { ...datas[index], biko: null };
    }
  });
  return datas;
}




//ソート
const doSort = (state, datas: RowData[], colKey: string, asc: boolean, showSubTotal: 'なし' | 'あり' | 'のみ'): RowData[] => {
  const grouping1 = showSubTotal !== 'なし' ? 'item' : '';
  console.log('doSort', colKey, asc, grouping1);

  //同じ階層同士のソート
  const sortCont = (TP, colKey, asc, objA, objB): number => {
    let comp = 0;
    const va = objA ? objA[colKey] : null;
    const vb = objB ? objB[colKey] : null;
    if (colKey === 'origin') {
      const va = objA ? objA['originCD'] : null;
      const vb = objB ? objB['originCD'] : null;
      comp = compareUtil.compareString(va, vb, asc);
    }
    //数値型
    else if (typeof va === 'number' || typeof vb === 'number') {
      comp = compareUtil.compareNumber(va, vb, asc);
    }
    else if (typeof va === 'string' || typeof vb === 'string') {
      comp = compareUtil.compareString(va, vb, asc);
    }
    //それでもソートできない場合、コード順とする
    if (comp == 0 && TP == 'grouping1') {
      const va = getGroupingCD(grouping1, objA);
      const vb = getGroupingCD(grouping1, objB);
      comp = compareUtil.compareString(va, vb, true);
    }
    return comp;
  };

  //小計あり
  if (grouping1) {
    datas.sort((a, b) => {
      let objA = a;
      let objB = b;

      const aGCD1 = getGroupingCD(grouping1, a);
      const bGCD1 = getGroupingCD(grouping1, b);

      //階層違い
      if (a.TP != b.TP) {
        const lvA = ['grouping1', 'item'].indexOf(a.TP);
        const lvB = ['grouping1', 'item'].indexOf(b.TP);
        if (Math.min(lvA, lvB) == 0) { //LV1
          if (aGCD1 == bGCD1) {  //同じコードならLV低い方を上
            return lvA - lvB;
          }
          if (a.TP != 'grouping1') {
            objA = datas.find(data => data.TP == 'grouping1' && getGroupingCD(grouping1, data) == aGCD1);
          }
          if (b.TP != 'grouping1') {
            objB = datas.find(data => data.TP == 'grouping1' && getGroupingCD(grouping1, data) == bGCD1);
          }
        }
      }
      //階層同じ
      else {
        if (aGCD1 != bGCD1) {
          objA = datas.find(data => data.TP == 'grouping1' && getGroupingCD(grouping1, data) == aGCD1);
          objB = datas.find(data => data.TP == 'grouping1' && getGroupingCD(grouping1, data) == bGCD1);
        }
      }

      return sortCont(objA ? objA.TP : objB ? objB.TP : null, colKey, asc, objA, objB);
    });
  }
  //小計なし
  else {
    datas.sort((a, b) => {
      return sortCont(a.TP, colKey, asc, a, b);
    });
  }
  switch (showSubTotal) {
    case 'なし':
      state.hiddenRows = datas.map((d, i) => { return { index: i, data: d } }).filter(m => m.data.TP === "grouping1").map(m => m.index);
      break;
    case 'あり':
      state.hiddenRows = [];
      break;
    case 'のみ':
      state.hiddenRows = datas.map((d, i) => { return { index: i, data: d } }).filter(m => m.data.TP !== "grouping1").map(m => m.index);
      break;
  }

  return datas;
}
const getGroupingCD = (key: 'item' | string, data: RowData): string => {
  if (!data) {
    return '';
  }
  switch (key) {
    case 'item':
      return data.centerCD + '_' + data.itemCD;
    default:
      return "";
  }
}

//配列データに変換
const convertRows = (datas: RowData[], colRowModel: MainPageColRowModel, showSubTotal: 'なし' | 'あり' | 'のみ'): {
  rows: [][],
  rowInfos: RowInfo[],
  mergeCells: { row: number, col: number, rowspan: number, colspan: number }[]
} => {
  console.log('store.convertRows');

  let mergeCells: { row: number, col: number, rowspan: number, colspan: number }[] = [];

  const rows = [];
  // let dataRowIndexes = [];
  const rowInfos: RowInfo[] = [];
  //set No.
  let grouping1No = 0;
  let itemNo = 0;
  datas.forEach((data, index1) => {
    if (showSubTotal !== 'なし' && data.TP === 'grouping1') {
      grouping1No++;
      itemNo = 0;
    } else if (data.TP === 'item') {
      itemNo++;
    }
    // const s = rows.length;
    const no: string = showSubTotal === 'なし' ? '' + itemNo : data.TP === 'grouping1' ? '' + grouping1No : (grouping1No + '-' + itemNo);

    const r = [];
    colRowModel.colKeys.forEach((colKey) => {
      switch (colKey) {
        case 'no':
          r.push(no); //No.
          break;
        default:
          r.push(data[colKey]);
          break;
      }
    });

    rows.push(r);
    rowInfos.push({
      row: rowInfos.length,
      data: data,
      dataIndex: index1,
      no: no,
    });
  });

  return {
    rows: rows,
    rowInfos: rowInfos,
    mergeCells: mergeCells,
  };
}

// no振りなおし
const resetRowNo = (datas: RowData[]): RowData[] => {
  //set No.
  datas = datas.map((row, index) => {
    return {
      ...row,
      no: "" + (index + 1),
    }
  });
  return datas;
}


//エラーチェック




export const getOptionLabel = (option: CodeName) => {
  return option && option.name ? (option.code + ' ' + option.name) : "";
}

const createSliceContent = (name: string) => createSlice({
  name: name,
  initialState,
  reducers: createReducerContent(),
});


//Page Slice Export
//mainPageTmp
export const mainPageTmpSlice = createSliceContent("mainPageTmp");
