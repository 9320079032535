import { CodeName } from "@/assets/commontype/CodeName";
import * as calcUtil from "@/util/calcUtil";
import { } from "./mainPageTmp";
import moment from 'moment';

export type DefaultVisibleKey = {
  name: string,
  visibleKey: string[],
};

export const originKbnList = [
  new CodeName({ code: null, name: '' }),
  new CodeName({ code: '10', name: '定番マスタ照合' }),
  new CodeName({ code: '20', name: '受注取消' }),
  new CodeName({ code: '30', name: '出荷調整' }),
  new CodeName({ code: '40', name: '出荷検品' }),
  new CodeName({ code: '60', name: '受領後' }),
];
//使用しなくなった発生個所
const originKbnListBak = [
  new CodeName({ code: '50', name: '出荷・受領' }),
];
export const getOriginKbnCD = (name: string): string => {
  const result = originKbnList.find(data => data.name == name);
  return result ? result.code : originKbnList[0].code;
}
export const getOriginKbnName = (cd: string, nullIsDefault: boolean, isBlank?: boolean): string => {
  let result = originKbnList.find(data => data.code == cd);
  if (!result) {
    result = originKbnListBak.find(data => data.code == cd);
  }
  return result ? (isBlank ? '' : result.name) : nullIsDefault ? originKbnList[0].name : cd;
}
export const optionAll = new CodeName({ code: 'all', name: '全て' });

export type RowData = {
  TP?: "item" | "grouping1",
  div?: string,
  id?: number,
  no?: string,
  seq?: number,
  uuid?: string,
  editState?: string[],

  //以下に貼り付け
  centerCD?: string | null,
  centerNM?: string | null,
  itemCD?: string | null,
  itemNM?: string | null,
  janCD?: string | null,
  makerCD?: string | null,
  makerNM?: string | null,
  categoryCD?: string | null,
  categoryNM?: string | null,
  blIrisu?: number | null,
  csIrisu?: number | null,
  teika?: string | null,
  tokuisakiCD?: string | null,
  tokuisakiNM?: string | null,
  torihikisakiCD?: string | null,
  slipNo?: string | null,
  detailNo?: number | null,
  syukkaDate?: string | null,
  nouhinDate?: string | null,
  jutyuPS?: number | null,
  jutyuBL?: number | null,
  nouhinPS?: number | null,
  nouhinBL?: number | null,
  keppinPS?: number | null,
  keppinBL?: number | null,
  keppinKingaku?: number | null,
  localSlipNo?: string | null,
  orderNo?: string | null,
  orderType?: string | null,
  originCD?: string | null,
  origin?: string | null,
  keppin?: string | null,
  keppinCD?: string | null,
  keppinNM?: string | null,
  biko?: string | null,
  responsibility?: string | null,
  reason?: string | null,
  updateUser?: string | null,
  updateDate?: string | null,

  group1NM?: string | null,
  tanaban?: string | null,
  tanabans?: string[] | null,

  yoteiDte?: string | null,
  yoteiBL?: number | null,

  shubaiDate?: string | null,
  teiban?: string | null,
}

export type DiffData = RowData;

//編集情報
export interface SaveInfoData {
  seq?: number,  //
  dataIndex?: number,
  uuid: string,

  div: string,
  id: number,

  originCD: string,
  keppinCD: string,
  biko: string,
  updateDate: string,

  //初回編集判定
  keppin_kbn_bak?: string,
}

//入力エラー情報
export interface ErrorInfoData {

}



//EOSフラグ
export const eosFlgName = (value: '1' | '0' | string) => {
  switch(value) {
    case '1':
      return 'EOS';
    case '0':
      return '手打ち';
    default:
      return '-';
  }
}

//入力状況
export const inputStatusName = (value: '1' | '3' | '4' | string) => {
  switch (value) {
    case '1':
      return '未入力';
    case '3':
      return '入力済';
    case '4':
      return '要確認';
    default:
      return '-';
  }
}




